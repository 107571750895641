import { template as template_fab32e4af7c44b399f71e793400abe10 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_fab32e4af7c44b399f71e793400abe10(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
