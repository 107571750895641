define("discourse/plugins/discourse-interstitials/discourse/components/interstitials-form-error", ["exports", "@ember/component", "@ember/template-factory"], function (_exports, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.error}}
    <div class="alert alert-error form-errors">
      {{html-safe this.error}}
    </div>
  {{/if}}
  */
  {
    "id": "eKChoJxD",
    "block": "[[[41,[30,0,[\"error\"]],[[[1,\"  \"],[10,0],[14,0,\"alert alert-error form-errors\"],[12],[1,\"\\n    \"],[1,[28,[35,2],[[30,0,[\"error\"]]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"div\",\"html-safe\"]]",
    "moduleName": "discourse/plugins/discourse-interstitials/discourse/components/interstitials-form-error.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, class extends _component.default {});
});