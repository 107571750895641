import { template as template_e77191a7bdc844b5a1b09d6e070cf7bc } from "@ember/template-compiler";
const FKControlMenuContainer = template_e77191a7bdc844b5a1b09d6e070cf7bc(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
