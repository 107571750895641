define("discourse/plugins/discourse-interstitials/discourse/initializers/discourse-interstitials", ["exports", "@ember/runloop", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/cookie", "discourse/lib/plugin-api", "discourse-common/lib/get-owner", "discourse/plugins/discourse-interstitials/discourse/components/modal/interstitial-alert"], function (_exports, _runloop, _ajax, _ajaxError, _cookie, _pluginApi, _getOwner, _interstitialAlert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const basePath = "/discourse-interstitial/my_interstitials";
  function shouldRenderInterstitialOnCurrentCategory(interstitial, currentRoute) {
    // only check for those paths with category and this interstitial requires a category
    if (currentRoute.paramNames.includes("category_slug_path_with_id")) {
      const paramList = currentRoute.params.category_slug_path_with_id.split("/");
      const categoryId = paramList[paramList.length - 1];
      if (interstitial.categories.includes(Number(categoryId))) {
        return true;
      }
    }

    // check for when in a post that might be in the right category
    if (currentRoute.parent.name === "topic") {
      // get current post category id
      const currentCategoryId = currentRoute.parent.attributes.category_id;
      if (interstitial.categories.includes(currentCategoryId)) {
        return true;
      }
    }
    return false;
  }
  function getAvailableInterstitial(interstitials) {
    let {
      filters,
      shouldOnlyReturnTheLast
    } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
      filters: null,
      shouldOnlyReturnTheLast: true
    };
    let interstitial = [];
    for (let i = 0; i < interstitials.length; i++) {
      let snoozeTime = (0, _cookie.default)(`snoozed_interstitial_${interstitials[i].id}`);
      if (snoozeTime && moment().isBefore(snoozeTime)) {
        continue;
      }
      if (filters && filters(interstitials[i])) {
        continue;
      }
      if (shouldOnlyReturnTheLast) {
        interstitial[0] = interstitials[i];
      } else {
        interstitial.push(interstitials[i]);
      }
    }
    return interstitial;
  }
  function showInterstitial(siteSettings, interstitial, shownInterstitials) {
    if (shownInterstitials.includes(interstitial)) {
      return;
    }
    if (interstitial.categories.length > 0) {
      const shouldRender = shouldRenderInterstitialOnCurrentCategory(interstitial, (0, _getOwner.getOwnerWithFallback)().lookup("service:router").currentRoute);
      if (!shouldRender) {
        return;
      }
    }
    shownInterstitials.push(interstitial);
    if (interstitial.anonymous_users && siteSettings.login_required) {
      return;
    }
    (0, _getOwner.getOwnerWithFallback)().lookup("service:modal").show(_interstitialAlert.default, {
      model: {
        basePath,
        ...interstitial
      }
    });
  }
  function handleExitIntent(siteSettings, interstitials, shownInterstitials) {
    document.addEventListener("mouseout", e => {
      if (!e.toElement && !e.relatedTarget) {
        let interstitialList = getAvailableInterstitial(interstitials, {
          filters: i => {
            return !i.exit_intent_trigger;
          }
        });
        if (interstitialList.length === 0) {
          return;
        }
        let interstitial = interstitial[0];
        showInterstitial(siteSettings, interstitial, shownInterstitials);
      }
    });
  }
  function randomABVariable() {
    return Math.round(Math.random());
  }
  function startTimer(context, siteSettings, interstitial, shownInterstitials) {
    let interval = interstitial.time_spent_trigger || 1000;
    return (0, _runloop.later)(context, () => {
      showInterstitial(siteSettings, interstitial, shownInterstitials);
    }, interval);
  }
  function registerImpression(siteSettings, interstitial) {
    let abVisible = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    if (!siteSettings.enable_interstitial_analytics) {
      return;
    }
    (0, _ajax.ajax)(`/discourse-interstitial/impressions`, {
      type: "POST",
      data: {
        impression: {
          discourse_interstitial_interstitial_id: interstitial.id,
          ab_visible: abVisible
        }
      }
    }).catch(e => this.set("error", (0, _ajaxError.extractError)(e)));
  }
  function initializeDiscourseInterstitial(api, siteSettings) {
    let shownInterstitials = [];
    (0, _ajax.ajax)(`${basePath}.json`).then(response => {
      let interstitials = getAvailableInterstitial(response.my_interstitials);
      if (interstitials.length === 0) {
        return;
      }
      let interstitial = interstitials[0];
      let abTestingCookie = `ab_testing_interstitial_${interstitial.id}`;
      if (siteSettings.enable_ab_testing && (0, _cookie.default)(abTestingCookie) !== "1") {
        if ((0, _cookie.default)(abTestingCookie) === "0") {
          registerImpression(siteSettings, interstitial, false);
          return;
        }
        if (randomABVariable() === 0) {
          (0, _cookie.default)(abTestingCookie, 0);
          registerImpression(siteSettings, interstitial, false);
          return;
        }
        (0, _cookie.default)(abTestingCookie, 1);
      }
      if (siteSettings.enable_exit_intent_trigger) {
        handleExitIntent(siteSettings, response.my_interstitials, shownInterstitials);
      }
      let timer = startTimer(this, siteSettings, interstitial, shownInterstitials);
      document.body.addEventListener("keydown", function () {
        (0, _runloop.cancel)(timer);
        timer = startTimer(this, siteSettings, interstitial, shownInterstitials);
      });

      // show interstitial on category
      api.onPageChange(() => {
        const interstitialList = getAvailableInterstitial(response.my_interstitials, {
          shouldOnlyReturnTheLast: false
        });
        if (interstitialList.length === 0) {
          return;
        }
        for (const interstitialItem of interstitialList) {
          if (interstitialItem.categories.length === 0) {
            return;
          }
          const shouldRender = shouldRenderInterstitialOnCurrentCategory(interstitialItem, (0, _getOwner.getOwnerWithFallback)().lookup("service:router").currentRoute);
          if (shouldRender) {
            (0, _runloop.cancel)(timer);
            timer = startTimer(this, siteSettings, interstitialItem, shownInterstitials);
          }
        }
      });
      api.onAppEvent("composer:closed", function () {
        timer = startTimer(this, siteSettings, interstitial, shownInterstitials);
      });
      api.onAppEvent("composer:will-open", function () {
        (0, _runloop.cancel)(timer);
      });
    });
  }
  var _default = _exports.default = {
    name: "discourse-interstitial",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      if (siteSettings.discourse_interstitial_enabled) {
        (0, _pluginApi.withPluginApi)("0.8.24", api => initializeDiscourseInterstitial(api, siteSettings));
      }
    }
  };
});