define("discourse/plugins/discourse-interstitials/discourse/components/modal-content", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.isHtml}}
    {{html-safe @content}}
  {{else}}
    {{cook-text rawText=@content}}
  {{/if}}
  */
  {
    "id": "uKlV/rGK",
    "block": "[[[41,[30,0,[\"isHtml\"]],[[[1,\"  \"],[1,[28,[35,1],[[30,1]],null]],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[28,[35,2],null,[[\"rawText\"],[[30,1]]]]],[1,\"\\n\"]],[]]]],[\"@content\"],false,[\"if\",\"html-safe\",\"cook-text\"]]",
    "moduleName": "discourse/plugins/discourse-interstitials/discourse/components/modal-content.hbs",
    "isStrictMode": false
  });
  class ModalContent extends _component2.default {
    get isHtml() {
      return /<\/?[a-z][\s\S]*>/i.test(this.args.content);
    }
  }
  _exports.default = ModalContent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ModalContent);
});