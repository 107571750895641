import { template as template_05c6748d1b244cfabf44084e3c833fdb } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_05c6748d1b244cfabf44084e3c833fdb(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
