import { template as template_dad72cd7878b427bb276bd90e10f41db } from "@ember/template-compiler";
const FKLabel = template_dad72cd7878b427bb276bd90e10f41db(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
