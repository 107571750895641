define("discourse/plugins/discourse-salesforce/discourse/connectors/after-topic-status/case-status", ["exports", "discourse/helpers/d-icon", "discourse-i18n", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _dIcon, _discourseI18n, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CaseStatus = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    {{~#if @outletArgs.topic.has_salesforce_case~}}
      <span title={{i18n "topic_statuses.case.help"}} class="topic-status">
        {{~icon "briefcase"~}}
      </span>
    {{~/if~}}
  
  */
  {
    "id": "0Y2rVNsK",
    "block": "[[[41,[30,1,[\"topic\",\"has_salesforce_case\"]],[[[10,1],[15,\"title\",[28,[32,0],[\"topic_statuses.case.help\"],null]],[14,0,\"topic-status\"],[12],[1,[28,[32,1],[\"briefcase\"],null]],[13]],[]],null]],[\"@outletArgs\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-salesforce/discourse/connectors/after-topic-status/case-status.js",
    "scope": () => [_discourseI18n.i18n, _dIcon.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "case-status:CaseStatus"));
  var _default = _exports.default = CaseStatus;
});