define("discourse/plugins/discourse-interstitials/discourse/components/fields/di-category-field", ["exports", "@ember/component", "@ember/object", "discourse/models/category", "discourse/plugins/discourse-interstitials/discourse/components/fields/di-base-field", "@ember/template-factory"], function (_exports, _component, _object, _category, _diBaseField, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <section class="field category-field">
    <div class="control-group">
      {{fields/di-field-label label=this.label field=this.field}}
  
      <div class="controls">
        {{category-selector
          categories=this.categories
          onChange=(action "setCategoryField")
          options=(hash maximum=this.field.maximum)
        }}
        {{fields/di-field-description description=this.description}}
      </div>
    </div>
  </section>
  */
  {
    "id": "R2DeneJb",
    "block": "[[[10,\"section\"],[14,0,\"field category-field\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n    \"],[1,[28,[35,2],null,[[\"label\",\"field\"],[[30,0,[\"label\"]],[30,0,[\"field\"]]]]]],[1,\"\\n\\n    \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n      \"],[1,[28,[35,3],null,[[\"categories\",\"onChange\",\"options\"],[[30,0,[\"categories\"]],[28,[37,4],[[30,0],\"setCategoryField\"],null],[28,[37,5],null,[[\"maximum\"],[[30,0,[\"field\",\"maximum\"]]]]]]]]],[1,\"\\n      \"],[1,[28,[35,6],null,[[\"description\"],[[30,0,[\"description\"]]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"section\",\"div\",\"fields/di-field-label\",\"category-selector\",\"action\",\"hash\",\"fields/di-field-description\"]]",
    "moduleName": "discourse/plugins/discourse-interstitials/discourse/components/fields/di-category-field.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _diBaseField.default.extend(dt7948.p({
    categories: null,
    init() {
      this._super(...arguments);
      const ids = this.field?.metadata?.value || [];
      const categories = _category.default.list().filter(c => ids.includes(c.id));
      this.set("categories", categories);
    },
    setCategoryField(categoryList) {
      this.onChangeField(this.field, categoryList.map(c => c.id));
      this.set("categories", categoryList);
    }
  }, [["method", "setCategoryField", [_object.action]]])));
});