import { template as template_3e3a4e2098b143d29c7de4a3e223f96c } from "@ember/template-compiler";
const FKText = template_3e3a4e2098b143d29c7de4a3e223f96c(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
